<template>
  <div class="card-criteria">
    <el-tooltip
      class="item"
      effect="dark"
      :content="data.criterionName"
      placement="bottom"
    >
      <p class="name-criteria">{{ data.criterionName }}</p>
    </el-tooltip>
    <p class="year-criteria">ปี {{ data.criterionYear }}</p>
    <el-button
      class="btn-link"
      @click="
        $router.push(
          page == 'user'
            ? `/user/detail/` + data._id
            : `/dashboard-credit/` + data._id
        )
      "
    >
      ดูข้อมูลผู้ประกอบการ
    </el-button>
    <div class="active" v-if="data.active">
      <span>Active</span>
    </div>
  </div>
</template>
<script>
export default {
  props: ["data", "page"],
};
</script>