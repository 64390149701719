<template>
  <DefultTempalte 
    v-loading.lock="loadingData"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading">
    <div class="box-card">
      <h1>ภาพรวมสินเชื่อ</h1>
      <div v-loading.lock="loading" element-loading-text="กำลังโหลดข้อมูล...">
        <div
          class="animate__animated animate__fadeIn animate__slow"
        >
          <el-row :gutter="30" v-if="tableCredit.length > 0">
            <el-col
              :span="24"
              :sm="12"
              :md="8"
              :lg="6"
              v-for="(credit, indexC) in tableCredit"
              :key="indexC"
              class="mg-b-5"
            >
              <CardListCriteria :data="credit" />
            </el-col>
          </el-row>
          <div class="text-center mg-t-3" v-else>
            <p class="font-30 font-weight-500">ไม่พบข้อมูล</p>
          </div>
        </div>
      </div>
      <div class="text-center mg-t-5">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="changePage"
          :current-page.sync="pageNow"
          :page-sizes="[16, 30, 50, 100, 500]"
          :page-size="16"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalPage"
        >
        </el-pagination>
        <!-- <el-pagination
          background
          layout="prev, pager, next"
          :total="totalPage"
          :page-size="limit"
          :current-page="pageNow"
          @current-change="changePage"
        ></el-pagination> -->
      </div>
    </div>
  </DefultTempalte>
</template>
<script>
import DefultTempalte from "@/template/DefaultTemplate";
import CardListCriteria from "@/components/card/CardListCriteria";
import { HTTP } from "@/service/axios";
import { mapState } from "vuex";

export default {
  components: {
    DefultTempalte,
    CardListCriteria,
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
    }),
  },
  mounted() {
    this.$store.commit("SET_PAGE_ACTIVE", "dashboard-credit");
    this.getDashboardCriterions();
  },
  data() {
    return {
      loadingData: true,
      loading: false,
      tableCredit: [],
      limit: 16,
      skip: 0,
      totalPage: 0,
      pageNow: 1,
    };
  },
  methods: {
    getDashboardCriterions() {
      let obj = {
        limit: this.limit,
        skip: this.skip,
      };
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.post(`dashboard/criterions`, obj)
        .then((res) => {
          if (res.data.success) {
            this.tableCredit = res.data.obj;
            this.totalPage = res.data.total;
            this.loading = false;
            this.loadingData = false;
          } else {
            this.loading = false;
            this.loadingData = false;
          }
        })
        .catch((e) => {
          this.loading = false;
          this.loadingData = false;
          if (typeof e.response != "undefined" && e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if(e.response.data.status == 502) {
              console.log('Bad Gateway');
            } else {
              this.alertCatchError(e);
            }
          }
        });
    },
    handleSizeChange(val) {
      this.limit = val;
      this.changePage(1);
    },
    changePage(page) {
      this.loading = true
      this.pageNow = page;
      this.skip = page * this.limit - this.limit;
      this.getDashboardCriterions();
    },
  },
};
</script>